<template>
    <div id="referral-code-widget">
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <p class="mb-4">
                    Ahora tus <span class="bold">clientes pueden crear sus propias solicitudes de crédito</span> y asignarte como su <span class="bold">proveedor de confianza</span>. 
                    Para que se registren en la red comparteles el siguiente <span class="bold">código QR</span> y así podrán crear su solicitud de crédito.
                </p>
                <div v-if="hasReferralCode" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
                    <vs-input
                        class="w-full"
                        label="Tu código de referido"
                        type="text"
                        v-model.lazy="referralCode"
                        v-validate="'required'"
                        :danger="hasError('referralCode')"
                        :danger-text="errorText('referralCode')"
                        :success="isSuccess('referralCode')"
                        :disabled="hasReferralCode && !isEditing"
                        name="referralCode"/>
                        <div class="flex">
                            <vs-button v-if="!isEditing" class="mt-4 mr-4" icon-pack="feather" icon="icon-edit" @click.stop="changeReferralCode()">Modificar</vs-button>
                            <vs-button v-else class="mt-4 mr-4" icon-pack="feather" icon="icon-save" @click.stop="requestUpdateReferal()">Guardar</vs-button>
                            <vs-button v-if="!isEditing" class="mt-4 p-2" type="border" icon-pack="feather" icon="icon-download-cloud" @click.stop="downloadQRCode()">Descargar código QR</vs-button>
                        </div>
                </div>
                <div v-else>
                    <vs-button v-if="!hasReferralCode" class="mt-4 p-2" icon-pack="feather" icon="icon-grid" @click.stop="generateReferralCode()">Solicitar Código</vs-button>
                </div>
                <!--  -->
                <!-- <vs-button v-if="!hasReferralCode" class="mt-4 p-2" type="border" icon-pack="feather" icon="icon-grid" @click.stop="generateReferralCode()">Generar Código</vs-button>
                <div v-else>
                    <vs-button class="mt-4 p-2" type="border" icon-pack="feather" icon="icon-download-cloud" @click.stop="getQRCode()">Descargar código QR</vs-button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import dateHelper from "@mixins/dateHelper";

    export default {
        data: () => ({
            referralCode: null,
            isEditing: false,
            auxCode: null,
        }),
        mixins: [dateHelper],
        computed: {
            hasReferralCode() {
                return this.SupplierReferralCode != null;
            }
        },
        beforeMount() {
            this.referralCode = this.SupplierReferralCode;
        },
        methods: {
            async generateReferralCode() {
                try {
                    this.showLoading(true);
                    const response = await axios.post(`/api/supplier/post/${this.SupplierId}/referral-code`);
                    if (response.data.success) {
                        this.$vs.notify({
                            title: 'Éxito',
                            text: 'Código de referido generado correctamente',
                            color: 'success',
                            position: 'top-right'
                        });

                        this.user.referral_code = response.data.referralCode;
                        location.reload()
                    } else {
                        this.$vs.notify({
                            title: 'Error',
                            text: response.data.mssg,
                            color: 'danger',
                            position: 'top-right'
                        });
                    
                    }
                    this.showLoading(false);             
                } catch (error) {
                    console.log(error);
                    this.showLoading(false);  
                }
            },
            getQRCode() {
                try {
                    let url = `${this.StorageURL}/documents/user/${this.UserId}/referral-codes/codigo-referido-redgirasol-${this.SupplierId}.pdf`;
                    window.open(url, "_blank");
                }
                catch (e) {
                    this.warn(e)
                    this.showError(e);
                }
            },
            changeReferralCode() {
                this.isEditing = true;
                this.auxCode = this.referralCode;
            },
            requestUpdateReferal() {
                const isValid = this.validateCode();

                if(!isValid) {
                    return;
                }

                this.$vs.dialog({
                    type: 'confirm',
                    color: 'warning',
                    title: 'Actualizar código de referido',
                    text: `¿Realmente deseas actualizar tu código? Recuerda que si actualizas el código de referido, los clientes que ya lo tengan no podrán asignarte como su proveedor de confianza con el código anterior y deberás actualizar el código QR que compartas con tus clientes.`,
                    acceptText: "Actualizar código",
                    cancelText: 'Cancelar',
                    accept: this.doUpdateReferal
                });

            },
            async doUpdateReferal() {
                try {
                    this.showLoading(true);

                    const res = await axios.put(`/api/loan/put/update-supplier-code`, {
                        supplier_id: this.SupplierId,
                        referral_code: this.referralCode
                    });

                    if(res.data.success == true) {
                        this.showLoading(false);
                        this.saveSuccessNotif();
                        this.user.referral_code = res.data.referred_code;
                        this.referralCode = res.data.referred_code;
                        this.auxCode = null;
                        this.isEditing = false;
                        this.downloadQRCode();
                    } else {

                    }
                } catch (error) {
                    this.showLoading(false);
                    console.log(error);
                }
            },
            async downloadQRCode() {
                try {
                    const url = `${this.ApiDomain}/api/loan/get/${this.SupplierId}/qr-code-pdf-preview?access_token=${this.AccessToken}`;
                    window.open(url, "_blank");
                } catch (error) {
                    console.log(error);
                }
            },
            validateCode() {
                const length = this.referralCode.length;
                let isValid = true;
                if(length < 8) {
                    this.missingFieldsNotif(null, "Tu código de referido debe tener al menos 8 caracteres.");
                    isValid = false;
                }

                let upperCaseCode = this.referralCode.toUpperCase();

                let includesRedGirasol = upperCaseCode.includes("REDGIRASOL");
                let includesGirasol = upperCaseCode.includes("GIRASOL");
                let includesRed = upperCaseCode.includes("RED");

                if(includesGirasol || includesRed || includesRedGirasol) {
                    this.missingFieldsNotif(null, "Tu código de referido no puede contener las palabras girasol, red o redgirasol.");
                    isValid = false;
                }

                return isValid;
            },
            hasError(val){
                return this.errors.has(val);
            },
            errorText(val){
                return this.errors.first(val);
            },
            isSuccess(val){
                let ff = this.fields[val];
                return (ff && ff.required && ff.valid);
            },
        }
    }
</script>