<template>
  <div>
    <vx-card class="mb-6">
      <div class="vx-row">
        <div class="vx-col w-full">
          <h1 class="rg-big-title extrabold rg-title">Mi <span class="orange">cuenta</span>.</h1>
          <p class="text-subtitle mt-4 bold">
            En este apartado podrás actualizar el alias y correo electrónico para acceder a tu cuenta.
          </p>
        </div>
      </div>
    </vx-card>

    <vx-card title="Código de referencia" class="mb-5" v-if="isMounted">
      <ReferralCodeWidget />
    </vx-card>

    <vx-card title="Alias" class="mb-5" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Tu alias es la manera en la que podrás identificarte en la Red. No podrá cambiarse o
            actualizarse una vez definido.</p>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input class="w-full"
                :label="hasAlias ? 'No es posible actualizar el alias' : 'Ingresa el alias que desees utilizar'"
                type="text" v-model.lazy="alias" v-validate="'required'" :danger="hasError('alias') && sent"
                :danger-text="errorText('alias')" :success="isSuccess('alias')" :disabled="hasAlias" name="alias" />
            </div>
          </div>

          <!-- Save Button -->
          <div class="vx-row" v-if="!hasAlias">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button class="mt-2" @click.stop="saveAliasRequest">Agregar</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>

    <vx-card title="Correo electrónico" class="mb-5" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Tu correo electrónico es otro medio importante para identificarte, además de que es el canal
            en donde más comunicación tendrás con la Red.
            Puedes consultarlo y actualizarlo aquí cuando lo necesites.</p>

          <div class="vx-row mb-5">
            <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <vs-input class="w-full" label="Tu correo electrónico" type="text" v-model.lazy="email"
                v-validate="'required|email'" :danger="hasError('email')" :danger-text="errorText('email')"
                :success="isSuccess('email')" :disabled="!editEmail" name="email" />
            </div>
          </div>

          <!-- Save Button -->
          <div class="vx-row" v-if="canEditEmail">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button v-if="!editEmail" class="mt-2" @click.stop="editEmail = true" icon-pack="feather"
                  icon="icon-edit">Modificar</vs-button>
                <vs-button v-if="editEmail" class="mt-2" @click.stop="editEmailRequest">Guardar</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>

    <!-- CLOSE ACCOUNT CARD -->
    <vx-card class="mb-5" title="Cerrar mi cuenta." v-if="isMounted">
      <div class="vx-row" v-if="!userHasCancellationRequest">
        <div class="vx-col lg:w-3/4 w-full">
          <p class="mb-5">En este apartado podrás cerrar tu cuenta en RedGirasol.
            Recuerda que para hacerlo tendrás que haber concluido cualquier compromiso como proveedor, es decir,
            que al momento de cerrarla no cuentes con proyectos:</p>
            <ul class="mb-5 ul-bullet ml-5">
              <li>Pendientes de ser revisados o aprobados sin estar archivados. En caso de tenerlos deberás archivarlos antes de cerrar tu cuenta.</li>
              <li>En proceso de fondeo, firma de contratos o en instalación.</li>
              <li>Entregados en un plazo menor a 6 meses. </li>
            </ul>
            <p class="mb-base bold">¡Esperamos que nunca te desconectes de la Red!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button type="border" color="dark" @click.stop="closeAccountRequest">Cerrar cuenta</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row" v-else>
        <div class="vx-col w-full">
          <p class="mb-4 bold">Tu cuenta está en proceso de ser cerrada. </p>
          <p>Realizaste la solicitud de cierre el <span class="bold"> {{ userCancellationDate }}</span>.</p>
          <p class="mb-5">Pasados 30 días, el acceso a tu cuenta se revocará, específicamente el día <span class="bold">
              {{ userCancellationLimit }}</span>.</p>
          <p class="mb-5">Si deseas mantener tu cuenta con nosotros, limpiar el planeta y seguir impulsando la
            transición energética de México
            da clic en el botón que se encuentra a continuación. ¡No te desconectes!</p>
          <!-- Save Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button type="border" color="dark" @click.stop="revokeAccountCancellation">Deseo continuar en la
                  Red</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

        <!-- cancellation account invalid popup -->
    <vs-popup :active.sync="cancellationAccountNotValid" title="Cierre de cuenta">
      <div class="p-3 pt-1">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h5>No es posible proceder con el cierre de cuenta.</h5>
            <p class="mt-4 mb-5">
              No es posible iniciar el proceso de cierre de tu cuenta, debido a que existen una o más condiciones que lo impiden:
            </p>

            <p class="bold mb-3" v-for="(validation, idx) in cancellationAccountValidations" :key="idx">
              - {{validation}}
            </p>
          </div>
          <div class="vx-col w-full mt-3">
            <div class="flex items-center flex-grow justify-left mt-1">
              <vs-button color="dark" @click.stop="cancellationAccountNotValid = false" >Finalizar</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- END CLOSE ACCOUNT CARD -->

    <!-- model popup -->
    <vs-popup title="Confirma tu alias" :active.sync="showPasswordFormPrompt">
      <div class="con-exemple-prompt">

        <p class="mb-5 bold">{{ modelFormPromptIndication }}</p>
        <p class="mb-5">Tu alias será: <strong>{{ alias }}</strong></p>
        <vs-input class="w-full mb-5" label="Contraseña" type="password" v-model.lazy="alias_pwd"
          v-validate="'required'" :danger="hasError('alias_pwd')" :danger-text="errorText('alias_pwd')"
          :success="isSuccess('alias_pwd')" name="alias_pwd" />

        <vs-button :disabled="!completeModelForm || $isLoading" @click="doUpdateAlias" color="primary"
          class="mr-5 mt-2">Enviar</vs-button>
        <vs-button @click="closeModelFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cancelar</vs-button>

      </div>
    </vs-popup>

    <vs-popup title="Confirma tu correo electrónico" :active.sync="showPasswordEmailFormPrompt">
      <div class="con-exemple-prompt">

        <p class="mb-5 bold">{{ modelFormPromptIndication }}</p>
        <p class="mb-5">Tu correo electrónico será: <strong>{{ email }}</strong></p>
        <vs-input class="w-full mb-5" label="Contraseña" type="password" v-model.lazy="email_pwd"
          v-validate="'required'" :danger="hasError('email_pwd')" :danger-text="errorText('email_pwd')"
          :success="isSuccess('email_pwd')" name="email_pwd" />

        <vs-button :disabled="!completeEmailForm || $isLoading" @click="doUpdateEmail" color="primary"
          class="mr-5 mt-2">Enviar</vs-button>
        <vs-button @click="closeEmailFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cancelar</vs-button>

      </div>
    </vs-popup>

  </div>
</template>
<script lang="js">
import ReferralCodeWidget from '../../../components/supplier/widgets/ReferralCodeWidget.vue';
import {mapState} from "vuex";
import dateHelper from "@mixins/dateHelper";

export default {
  mixins: [dateHelper],
  async mounted() {
    this.isMounted = false
    this.showLoading(true);
    await this.getUserData();
    this.showLoading(false);
    this.isMounted = true
  },
  components: {
    ReferralCodeWidget
  },
  data() {
    return {
      email: null,
      new_email: {
        email: '',
        password: '',
        code: '',
      },
      alias: '',
      email_error: '',
      loading_email: false,
      email_confirm_error: '',
      general_error: '',
      edit_email: false,
      set_alias: false,
      loading_alias: false,
      writing: true,
      alias_pwd: null,
      alias_error: '',

      isMounted: false,
      failed: false,
      hasAlias: false,
      showPasswordFormPrompt: false,
      modelFormPromptIndication: "Escribe tu contraseña actual para confirmar",

      showPasswordEmailFormPrompt: false,
      canEditEmail: true,

      userData: {},
      sent: false,
      editEmail: false,
      email_pwd: null,

      cancellationAccountNotValid: null,
      cancellationAccountValidations: [],
    }
  },
  watch: {
    edit_email: function () {
      this.new_email.password = '';
      this.new_email.email = this.email;
      this.new_email.code = '';
      this.email_error = '';
      this.email_confirm_error = '';
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    completeModelForm() {
      return this.alias_pwd != null;
    },
    completeEmailForm() {
      return this.email_pwd != null;
    },
    userHasCancellationRequest(){
      return this.roleGeneralData.cancellationAccountRequest !== null;
    },
    userCancellationDate(){
      if(this.roleGeneralData.cancellationAccountRequest !== null){
        return this.explicitDateFormat(this.roleGeneralData.cancellationAccountRequest);
      }
    },
    userCancellationLimit(){
      if(this.roleGeneralData.cancellationAccountLimit !== null){
        return this.regularDateFormat(this.roleGeneralData.cancellationAccountLimit);
      }
    }
  },
  methods: {
    async getUserData() {
      try {
        let res = await axios.get(`api/v1/supplier/${this.SupplierId}?with[]=user`);
        this.userData = res.data.user;
        this.hasAlias = this.userData.alias != null;
        this.alias = this.userData.alias;
        this.email = this.userData.email;
      }
      catch (error) {
        this.warn(error);
      }
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async saveAliasRequest() {
      this.sent = true;
      let r = await this.$validator.validate('alias');
      if (!r) {
        return;
      }
      await this.testAlias();
    },
    async testAlias() {
      try {
        let res = await axios.post('/api/auth/user/alias/check', { alias: this.alias });
        if (res.data == true) {
          this.openModelFormPrompt();
        }
        else {
          this.errorNotif("No es posible actualizar", "Este alias ya existe, por favor intenta con otro", 8000);
        }
      }
      catch (error) {
        this.failedOperationNotif();
      }
    },
    async confirmAliasChange() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Actualizar alias',
        text: `Confirma que deseas cambiar el alias de tu cuenta`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        accept: this.openModelFormPrompt
      });
    },
    async doUpdateAlias() {
      try {
        await axios.put('/api/auth/user/alias', { alias: this.alias, password: this.alias_pwd });
        this.hasAlias = true;
        this.saveSuccessNotif();
      }
      catch (error) {
        this.failedOperationNotif();
      }
      this.closeModelFormPrompt();
    },
    openModelFormPrompt() {
      this.alias_pwd = null;
      this.showPasswordFormPrompt = true;
    },
    closeModelFormPrompt() {
      this.showPasswordFormPrompt = false;
      this.alias_pwd = null;
    },

    async editEmailRequest() {
      let r = await this.$validator.validate('email');
      if (!r) {
        return;
      }
      await this.testEmail();
    },
    async testEmail() {
      try {
        let res = await this.publicApiPost('/api/auth/user/email/validate', { email: this.email });
        if (!res.data.is_valid) {
          this.errorNotif("No es posible actualizar", "Este correo electrónico ya existe, por favor intenta con otro", 8000);
        }
        else {
          this.openEmailFormPrompt();
        }
      }
      catch (error) {
        this.failedOperationNotif();
      }
    },
    async doUpdateEmail() {
      try {
        this.showLoading(true);
        const res = await axios.post('/api/auth/user/email/request', {
          email: this.email,
          password: this.email_pwd
        });
        this.showLoading(false);
        this.showPasswordEmailFormPrompt = false;
        this.successNotifDialog('Solicitud enviada correctamente', res.data.message);
        this.editEmail = false
        this.hasAlias = true;
        await this.intializeData();
      }
      catch (error) {
        this.failedOperationNotif();
      }
      this.closeEmailFormPrompt();
    },
    async intializeData() {
      await this.onUserInfoUpdated();
      this.isMounted = false
      this.showLoading(true)
      await this.getUserData();
      this.isMounted = true
      this.showLoading(false)
    },
    openEmailFormPrompt() {
      this.email_pwd = null;
      this.showPasswordEmailFormPrompt = true;
    },
    closeEmailFormPrompt() {
      this.email_pwd = null;
      this.showPasswordEmailFormPrompt = false;
    },
    async closeAccountRequest(){
      // solicitar datos de validacion de cierre de cuenta
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v1/supplier/${this.SupplierId}/check-cancel-conditions`);
        const has_lead_loans = res.data.has_lead_loans;
        const has_in_process_loans = res.data.has_in_process_loans;
        const has_delivered_loans = res.data.has_delivered_loans;
        let validations = [];
        
        if(has_lead_loans) {
          validations.push('Tienes proyectos pendientes de ser revisados o aprobados sin estar archivados. En caso de tenerlos deberás archivarlos antes de cerrar tu cuenta.');
        }

        if(has_in_process_loans) {
          validations.push('Tienes proyectos en proceso de fondeo, firma de contratos o en instalación.');
        }

        if(has_delivered_loans) {
          validations.push('Tienes proyectos entregados en un plazo menor a 6 meses.');
        }

        if (validations.length > 0){
          this.cancellationAccountValidations = validations;
          this.cancellationAccountNotValid = true;
        } else {
          let mssg = 'Confirma que deseas proceder con el cierre de tu cuenta en RedGirasol.' +
            '\n\nA partir de este momento contarás con un plazo de 30 días para poder acceder a tu cuenta y verificar movimientos, datos, etc. ' +
            'Una vez cumplidos, no podrás volver a acceder.';
          this.confirmationDialog(
            this.doCancelAccount,
            'Confirma la acción',
            mssg,
            'Confirmar'
          );
        }
      }
      catch (e) {
        this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
      }
      this.showLoading(false)
    },
    async doCancelAccount(){
      this.showLoading(true)
      try {
        let payload = {};
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v1/supplier/${this.SupplierId}/post-cancel-account`, payload);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Petición de cierre de cuenta', 'El proceso de cierre de cuenta ha sido iniciado correctamente.' +
          ' Recuerda que tendrás 30 días para seguir usando tu cuenta, antes de que el acceso se revoque completamente.');
      }
      catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
    async revokeAccountCancellation(){
      let mssg = 'Confirma que deseas cancelar la solicitud de cierre de tu cuenta en RedGirasol.' +
        '\n\nTu cuenta se mantendrá sin cambios y podrás usarla de manera normal.';
      this.confirmationDialog(
        this.doRevokeAccountCancellation,
        'Confirma la acción',
        mssg,
        'Confirmar'
      );
    },
    async doRevokeAccountCancellation(){
      this.showLoading(true)
      try {
        await axios.post(`api/v1/supplier/${this.SupplierId}/revoke-cancel-account`);
        await this.onUserInfoUpdated();
        this.successNotifDialog('Solicitud de cierre de cuenta cancelada', 'Podrás continuar utilizando tu cuenta de forma normal.');
      }
      catch (e) {
        if(e && e.response && e.response.data && e.response.data.error){
          this.errorNotifDialog('Error en petición', e.response.data.error);
        }
        else {
          this.errorNotifDialog('Error en petición', 'No ha sido posible obtener la información, intente más tarde.');
        }
      }
      this.showLoading(false)
    },
  },
}
</script>
<style>
.rg-title {
  font-size: 3rem !important;
}
</style>